exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artigos-inteligencia-artificial-js": () => import("./../../../src/pages/artigos-inteligencia-artificial.js" /* webpackChunkName: "component---src-pages-artigos-inteligencia-artificial-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-cursos-inteligencia-artificial-js": () => import("./../../../src/pages/cursos-inteligencia-artificial.js" /* webpackChunkName: "component---src-pages-cursos-inteligencia-artificial-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-noticias-inteligencia-artificial-js": () => import("./../../../src/pages/noticias-inteligencia-artificial.js" /* webpackChunkName: "component---src-pages-noticias-inteligencia-artificial-js" */),
  "component---src-pages-privacidade-js": () => import("./../../../src/pages/privacidade.js" /* webpackChunkName: "component---src-pages-privacidade-js" */),
  "component---src-pages-produtos-js": () => import("./../../../src/pages/produtos.js" /* webpackChunkName: "component---src-pages-produtos-js" */),
  "component---src-pages-servicos-js": () => import("./../../../src/pages/servicos.js" /* webpackChunkName: "component---src-pages-servicos-js" */),
  "component---src-pages-sobre-nos-js": () => import("./../../../src/pages/sobre-nos.js" /* webpackChunkName: "component---src-pages-sobre-nos-js" */),
  "component---src-pages-topicos-js": () => import("./../../../src/pages/topicos.js" /* webpackChunkName: "component---src-pages-topicos-js" */),
  "component---src-pages-tudo-sobre-js": () => import("./../../../src/pages/tudo-sobre.js" /* webpackChunkName: "component---src-pages-tudo-sobre-js" */),
  "component---src-pages-tutoriais-inteligencia-artificial-js": () => import("./../../../src/pages/tutoriais-inteligencia-artificial.js" /* webpackChunkName: "component---src-pages-tutoriais-inteligencia-artificial-js" */),
  "component---src-templates-article-template-js": () => import("./../../../src/templates/article-template.js" /* webpackChunkName: "component---src-templates-article-template-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-categorie-template-js": () => import("./../../../src/templates/categorie-template.js" /* webpackChunkName: "component---src-templates-categorie-template-js" */),
  "component---src-templates-course-template-js": () => import("./../../../src/templates/course-template.js" /* webpackChunkName: "component---src-templates-course-template-js" */),
  "component---src-templates-news-template-js": () => import("./../../../src/templates/news-template.js" /* webpackChunkName: "component---src-templates-news-template-js" */),
  "component---src-templates-tag-template-js": () => import("./../../../src/templates/tag-template.js" /* webpackChunkName: "component---src-templates-tag-template-js" */),
  "component---src-templates-tutorial-template-js": () => import("./../../../src/templates/tutorial-template.js" /* webpackChunkName: "component---src-templates-tutorial-template-js" */)
}

